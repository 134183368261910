import { api } from '.';

export function getMerchantAccountingReports(payload) {
  payload = { page: 1, limit: 20, ...payload };
  return api('/v1/reports/getMerchantAccountingReports', payload);
}

export function getAgentAccountingReports(payload) {
  payload = { page: 1, limit: 20, ...payload };
  return api('/v1/reports/getAgentAccountingReports', payload);
}

export function getPlatformOrderReport(payload) {
  payload = { page: 1, limit: 20, ...payload };
  return api('/v1/reports/getPlatformOrderReport', payload);
}

export function getChannelAccountingReports(payload) {
  payload = { page: 1, limit: 20, ...payload };
  return api('/v1/reports/getChannelAccountingReports', payload);
}

export function getChildChannelAccountingReports(payload) {
  payload = { page: 1, limit: 20, ...payload };
  return api('/v1/reports/getChildChannelAccountingReports', payload);
}

export function getCardAccountingReports(payload) {
  payload = { page: 1, limit: 20, ...payload };
  return api('/v1/reports/getCardAccountingReports', payload);
}
