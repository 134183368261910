var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"w100p",attrs:{"flat":""}}),_c('div',{staticClass:"d-sm-flex mb-3 text-Heading6 Medium d-none"},[_vm._v(" "+_vm._s(_vm.$store.getters['app/getRouteTitle'])+" "),_c('v-spacer')],1),_c('FilterBox',{attrs:{"open":_vm.isFilterOpen,"loading":_vm.isLoading,"options":_vm.filterOptions},on:{"update:open":function($event){_vm.isFilterOpen=$event},"search":_vm.fetchData},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('SizeBox',{attrs:{"height":"24"}}),_c('v-card',{attrs:{"outlined":"","color":_vm.$vuetify.breakpoint.smAndDown ? 'transparent' : null}},[_c('v-card-text',{staticClass:"d-flex align-center pb-0 pb-sm-4"},[_c('v-spacer'),_c('RefreshBtn',{attrs:{"fetchData":_vm.fetchData,"loading":_vm.isLoading}}),_c('SizeBox',{attrs:{"width":"14"}}),_c('ExportBtn',{attrs:{"items":_vm.result.list,"headers":_vm.headers,"file-name":`${
          _vm.$store.getters['app/getRouteTitle']
        } ${_vm.$moment().format('YYYY-MM-DD_H:mm:ss')}`,"sheet-name":"sheetName","disabled":!_vm.$getGrantValue('merchant_accounting_reports.export')}})],1),_c('DataTable',{attrs:{"loading":_vm.isLoading,"list":_vm.result.list,"total":_vm.result.total,"fields":_vm.headers,"replace":[
        'merchant_type',
        'total_collect_order_amt|currency',
        'total_collect_order_credit_amt|currency',
        'total_collect_order_fee|currency',
        'total_paid_collect_order_amt|currency',
        'capital_amt|currency',
        'frozen_amt|currency',
        'total_pay_order_amt|currency',
        'total_pay_order_fee|currency',
        'total_pay_order_actual_amt|currency'
      ]},on:{"get-list":_vm.fetchData},scopedSlots:_vm._u([{key:"item.merchant_type",fn:function({ item }){return [_c('v-chip',{attrs:{"color":"Secondary100","small":""}},[_vm._v(" "+_vm._s(item.merchant_type)+" ")])]}}])})],1),_c('FunctionFab',{attrs:{"no-filter":!_vm.filterOptions.length,"no-info":!Object.entries(_vm.result.header_info).length},on:{"info":function($event){_vm.isInfoOpen = true},"refresh":function($event){return _vm.fetchData()},"open-filter":function($event){_vm.isFilterOpen = true}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }